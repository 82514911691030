import React, { useState, FormEventHandler } from "react"
import { PageRendererProps, navigate } from "gatsby"
import styled from "styled-components"
import SEO from "@/components/SEO"
import qs from "qs"
import PageTitle from "@/components/PageTitle"
import Select from "@/components/Select"

type ContactsPageProps = PageRendererProps

type ContactType = "hackathon" | "advisor" | "devops" | "other"

const title = "お問い合わせ"
const description =
  "ZEN ARCHITECTSへのお問い合わせはこちらのページからお願いします。"

const portalId = process.env.GATSBY_HUBSPOT_PORTAL_ID
const formGuid = process.env.GATSBY_HUBSPOT_FORM_GUID

if (!portalId || !formGuid) {
  throw new Error(
    "process.env.GATSBY_HUBSPOT_PORTAL_ID and process.env.GATSBY_HUBSPOT_FORM_GUID must be defined."
  )
}

const Contacts: React.FC<ContactsPageProps> = ({ location }) => {
  const queryIncludes = (name: string): boolean =>
    qs.parse(location.search, { ignoreQueryPrefix: true })[name] === "true"

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [body, setBody] = useState("")
  const [type, setType] = useState<ContactType>(
    queryIncludes("hackathon")
      ? "hackathon"
      : queryIncludes("advisor")
      ? "advisor"
      : queryIncludes("devops")
      ? "devops"
      : "other"
  )
  const [sending, setSending] = useState(false)

  const handleSubmit: FormEventHandler = async (event) => {
    event.preventDefault()
    setSending(true)

    const baseFields = [
      {
        name: "name",
        value: name,
      },
      {
        name: "email",
        value: email,
      },
      {
        name: "body",
        value: body,
      },
    ]
    const fields =
      type === "other"
        ? baseFields
        : [
            ...baseFields,
            {
              name: "type",
              value: type,
            },
          ]

    await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          portalId,
          formGuid,
          fields,
        }),
      }
    )
    navigate("/contacts-complete")
  }

  return (
    <>
      <SEO
        title={title}
        description={description}
        pathname={location.pathname}
      />
      <Root>
        <PageTitle>contact</PageTitle>
        <Description>{description}</Description>
        <Form method="post" onSubmit={handleSubmit}>
          <FormItem half>
            <Label htmlFor="name">お名前</Label>
            <InputText
              id="name"
              name="name"
              type="text"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </FormItem>
          <FormItem half>
            <Label htmlFor="email">メールアドレス</Label>
            <InputText
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </FormItem>
          <FormItem half>
            <Label htmlFor="type">お問い合わせ種別</Label>
            <Select
              value={type}
              onChange={(event) => setType(event.target.value as ContactType)}
              options={[
                {
                  value: "other",
                  label: "",
                },
                { value: "advisor", label: "ZEN Advisor について" },
                { value: "devops", label: "DevOps with GitHub について" },
                {
                  value: "hackathon",
                  label: "Azure Light-up Hackathon について",
                },
              ]}
            />
          </FormItem>
          <FormItem>
            <Label htmlFor="body">お問い合わせ内容</Label>
            <Textarea
              id="body"
              name="body"
              value={body}
              onChange={(event) => setBody(event.target.value)}
            />
          </FormItem>
          <FormItem>
            {!sending ? (
              <Button type="submit" disabled={!name || !email || !body}>
                送信
              </Button>
            ) : (
              <Button disabled>送信中 ...</Button>
            )}
          </FormItem>
        </Form>
      </Root>
    </>
  )
}

const Root = styled.div`
  background-color: ${(props) => props.theme.secondaryLightColor};

  @media ${(props) => props.theme.maxSmDown} {
    padding: 80px 0 60px;
  }
  @media ${(props) => props.theme.minMdUp} {
    padding: 130px 15px 100px;
  }
`

const Description = styled.p`
  margin: 0 auto;
  text-align: center;
  line-height: 1.5;
  font-size: 14px;

  @media ${(props) => props.theme.minMdUp} {
    max-width: 720px;
    padding: 20px 0 40px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding: 15px 30px 30px;
  }
`

const Form = styled.form`
  background-color: #fff;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  @media ${(props) => props.theme.minMdUp} {
    padding: 40px 20px 10px;
    max-width: 720px;
    border-radius: 2px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding: 30px 0 10px;
  }
`

const FormItem = styled.div<{ half?: boolean; horizontal?: boolean }>`
  margin-bottom: 30px;
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? "row" : "column")};
  align-items: ${(props) => (props.horizontal ? "center" : "initial")};

  @media ${(props) => props.theme.minMdUp} {
    width: ${(props) => (props.half ? "50%" : "100%")};
    padding: 0 20px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    width: 100%;
    padding: 0 30px;
  }
`

const Label = styled.label`
  color: ${(props) => props.theme.primaryDarkColor};
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`

const InputText = styled.input`
  background-color: ${(props) => props.theme.secondaryLightColor};
  transition: border-color 0.3s;
  padding: 10px;
  border: none;
  border-bottom: solid 2px #ccc;
  font-size: 16px;
  width: 100%;
  border-radius: 0;

  &:focus {
    border-color: ${(props) => props.theme.primaryColor};
    outline: none;
  }
`

const Textarea = styled.textarea`
  background-color: ${(props) => props.theme.secondaryLightColor};
  transition: border-color 0.3s;
  border: none;
  border-bottom: solid 2px #ccc;
  width: 100%;
  height: 300px;
  font-size: 16px;
  padding: 10px;
  border-radius: 0;

  &:focus {
    border-color: ${(props) => props.theme.primaryColor};
    outline: none;
  }
`

const Button = styled.button`
  background: linear-gradient(to left, #409ea6 0%, #2c6c8b 51%, #203f56 100%);
  width: 200px;
  font-size: 18px;
  appearance: none;
  border: none;
  padding: 14px 0;
  color: ${(props) => props.theme.textLightColor};
  border-radius: 30px;
  cursor: pointer;
  border: 2px solid;
  margin: 10px auto 0;
  display: block;

  &:hover:not(:disabled) {
    background: ${(props) => props.theme.textLightColor};
    color: ${(props) => props.theme.primaryColor};
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    background: ${(props) => props.theme.secondaryColor};
    cursor: auto;
  }
`

export default Contacts
