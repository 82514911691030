import React from "react"
import styled from "styled-components"

type Props = {
  value: any
  onChange: React.ChangeEventHandler<HTMLSelectElement>
  options: { value: any; label: string }[]
}

const Select: React.FC<Props> = ({ options, value, onChange }) => {
  return (
    <Root>
      <select className="select" value={value} onChange={onChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </Root>
  )
}

const Root = styled.div`
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    border-right: 2px solid #ccc;
    border-bottom: 2px solid #ccc;
    right: 14px;
    transform: rotate(45deg);
    top: 14px;
  }

  .select {
    width: 100%;
    appearance: none;
    background-color: ${(props) => props.theme.secondaryLightColor};
    transition: border-color 0.3s;
    padding: 10px 36px 10px 10px;
    border: none;
    border-bottom: solid 2px #ccc;
    font-size: 16px;

    &:focus {
      border-color: ${(props) => props.theme.primaryColor};
      outline: none;
    }
  }
`

export default Select
